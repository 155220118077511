import { Component, OnInit } from '@angular/core';
import { PathwaysAuthService } from '@pw/account/auth';

@Component({
  standalone: false,
  templateUrl: './local-sign-out.component.html',
})
export class LocalSignOutPageComponent implements OnInit {
  constructor(private readonly authService: PathwaysAuthService) {}

  ngOnInit(): void {
    this.authService.logoutLocal();
  }
}
