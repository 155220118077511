import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'pw-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pw-preview.component.html',
  styleUrls: ['./pw-preview.component.scss'],
})
export class PwPreviewNotificationComponent {
  previewUrl = 'https://pathways.kaplaninternational.com/course/view.php?id=4036';
}
