import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pw-input-password',
  standalone: false,
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPasswordComponent {
  @Input() inputId: string = undefined;
  @Input() disabled = false;
  @Input() labelText = '';
  @Input() hintText = '';
  @Input() placeholder = '';
  @Input() inputValue!: string;
  @Input() invalid = false;
  @Input() errorText = '';
  @Input() newPassword = false;
  @Output() inputValueChange = new EventEmitter<string>();
  @Output() fieldBlur = new EventEmitter<void>();
  @Output() fieldFocus = new EventEmitter<void>();

  public showPassword = false;

  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
