import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { StudentNotification, StudentNotificationStatusEnum, TaskItem, TaskItemNameEnum } from '@pw/notification-data-access';
import { environment } from '@pw/shared/environment';
import { take, tap } from 'rxjs';
import { NotificationPanelService } from '../../../../services';

@Component({
  selector: 'pw-pre-arrival-tasks-notification',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './pre-arrival-tasks-notification.component.html',
  styleUrls: ['./pre-arrival-tasks-notification.component.scss'],
})
export class PreArrivalTasksNotificationComponent implements OnChanges {
  @Input() preArrivalTasksNotification: StudentNotification | undefined;
  studentNotificationStatusEnum = StudentNotificationStatusEnum;

  editImg = '/assets/icons/edit.svg';
  onlineRegistrationTask: TaskItem | undefined;
  addressConfirmationTask: TaskItem | undefined;
  collegeArrivalConfirmationTask: TaskItem | undefined;
  collegeTasks: { code: string; tasks: { name: string; url: string }[] }[] = [];

  private arrivalConfirmationUrl = '';
  private onlineRegistrationUrl = '';

  private readonly taskUrlQuery = '?referral_source=student_account';
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly breakpointObserverService = inject(BreakpointObserverService);

  constructor() {
    for (const [key, value] of Object.entries(environment.PRE_ARRIVAL_CHECKLIST_TASKS)) {
      this.collegeTasks.push({
        code: key,
        tasks: value,
      });
    }
  }

  ngOnChanges({ preArrivalTasksNotification }: SimpleChanges): void {
    if (preArrivalTasksNotification?.currentValue) {
      this.collegeArrivalConfirmationTask = this.preArrivalTasksNotification.tasks?.find((task) => task.name === TaskItemNameEnum.college_arrival_confirmation);
      this.onlineRegistrationTask = this.preArrivalTasksNotification.tasks?.find((task) => task.name === TaskItemNameEnum.online_registration);
      this.addressConfirmationTask = this.preArrivalTasksNotification.tasks?.find((task) => task.name === TaskItemNameEnum.address_confirmation);

      const tasks = this.collegeTasks.find((college) => college.code === this.preArrivalTasksNotification.collegeCode)?.tasks;
      this.arrivalConfirmationUrl = tasks?.find((task) => task.name === TaskItemNameEnum.college_arrival_confirmation)?.url;
      this.onlineRegistrationUrl = tasks?.find((task) => task.name === TaskItemNameEnum.online_registration)?.url;
    }
  }

  openOnlineRegistration() {
    if (this.onlineRegistrationUrl) {
      window.open(`${this.onlineRegistrationUrl}${this.taskUrlQuery}`, '_blank');
    }
  }

  openConfirmUkAddress() {
    this.notificationPanelService.toggleConfirmUkAddress(true);
    this.breakpointObserverService.isMobile$
      .pipe(
        take(1),
        tap((value) => this.notificationPanelService.togglePanel(!value, true)),
      )
      .subscribe();
  }

  openCollegeArrivalConfirmation() {
    if (this.arrivalConfirmationUrl) {
      window.open(`${this.arrivalConfirmationUrl}${this.taskUrlQuery}`, '_blank');
    }
  }
}
