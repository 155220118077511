import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { PathwaysAuthService, sessionDurationHours } from '@pw/account/auth';
import { PathwaysAccountRouteParams } from '@pw/shared/environment';
import { SignOutType } from '@pw/shared/types';
import { Subscription } from 'rxjs';

@Component({
  templateUrl: './sign-out.component.html',
  standalone: false,
  styleUrls: ['./sign-out.component.scss'],
})
export class SignOutPageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  public isMobile = false;
  public signOutTitle = '';
  public signOutType: SignOutType = null;
  public sessionDurationHours = sessionDurationHours;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly breakpointObserverService: BreakpointObserverService,
    private readonly authService: PathwaysAuthService,
  ) {}

  ngOnInit(): void {
    this.signOutType = this.activatedRoute.snapshot?.paramMap.get(PathwaysAccountRouteParams.TYPE) as SignOutType;
    if (this.isSignOutExpiredPage()) {
      this.signOutTitle = $localize`Your session has expired`;
    } else {
      this.subscriptions.add(
        this.breakpointObserverService.size$.subscribe((currentSize) => {
          this.isMobile = ['xs', 'sm'].includes(currentSize);
          this.signOutTitle = this.isMobile ? $localize`Sign out successful` : $localize`Sign out<br/>successful`;
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  goToSignIn(): void {
    this.authService.login();
  }

  isSignOutExpiredPage(): boolean {
    return this.signOutType === SignOutType.EXPIRED;
  }
}
