import { Component } from '@angular/core';
import { PathwaysAuthService } from '@pw/account/auth';

@Component({
  selector: 'pw-password-changed',
  standalone: false,
  templateUrl: './password-changed.component.html',
  styleUrls: ['./password-changed.component.scss'],
})
export class PasswordChangedComponent {
  constructor(private readonly authService: PathwaysAuthService) {}

  goToSignIn(): void {
    this.authService.login();
  }
}
