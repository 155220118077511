import { CommonModule, DatePipe } from '@angular/common';
import { Component, inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AttendanceStat, StudentAttendanceService } from '@pw/profile-data-access';
import { MenuItem } from 'primeng/api';
import { AttendanceFilterComponent } from '../attendance-filter/attendance-filter.component';

@Component({
  selector: 'pw-attendance-percentage',
  standalone: true,
  imports: [CommonModule, AttendanceFilterComponent],
  providers: [DatePipe],
  templateUrl: './attendance-percentage.component.html',
  styleUrls: ['./attendance-percentage.component.scss'],
})
export class AttendancePercentageComponent implements OnChanges {
  @Input() attendanceStat!: AttendanceStat;
  @Input() showFilter = false;

  menuItems: MenuItem[] = [];
  isDataAvailable = false;
  attendanceLevel = '';

  private readonly attendanceService = inject(StudentAttendanceService);

  ngOnChanges({ attendanceStat }: SimpleChanges): void {
    if (attendanceStat?.currentValue) {
      this.menuItems = this.attendanceService.getMenuItemsByFrequency(this.attendanceStat.frequency);
      this.isDataAvailable = Boolean(this.attendanceStat.percentage) || this.attendanceStat.hasAbsence;
      this.attendanceLevel = this.attendanceStat?.percentage >= 85 ? 'high' : this.attendanceStat?.percentage >= 70 ? 'medium' : 'low';
    }
  }
}
