import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { Observable } from 'rxjs';
import { TemplateNames } from '@pw/shared/types';

@Component({
  selector: 'pw-public-page',
  standalone: false,
  templateUrl: './public-page.component.html',
  styleUrls: ['./public-page.component.scss'],
})
export class PublicPageComponent {
  @Input() logoSrc = 'assets/logos/kaplan-pathways-white-logo.svg';
  @Input() logoAlt = 'Kaplan International Pathways';
  @Input() mobileLogoSrc = 'assets/logos/kaplan-pathways-blue-logo.svg';
  @Input() sideImageSrc = '';
  @Input() pageTitle = '';
  @Input() mobilePageTitle = '';
  @Input() mobileImageSrc = '';
  @Input() descriptions: string[] = [];
  @Input() hideDescriptions = false;
  @Input() wideContent = false;
  @Input() stickyFooter = true;
  @Input() template = '';

  @ViewChild('alternativeBlock', { read: ElementRef, static: true }) alternativeBlock: ElementRef<HTMLElement>;

  isMobile$: Observable<boolean>;
  templatesNames = TemplateNames;

  private readonly breakpointObserverService = inject(BreakpointObserverService);

  constructor() {
    this.isMobile$ = this.breakpointObserverService.isMobile$;
  }

  hasAlternativeBlock(): boolean {
    return !!this.alternativeBlock.nativeElement?.innerText?.trim();
  }
}
