import { inject, Injectable } from '@angular/core';
import { Memoize } from '@klg/shared/utils-http';
import { StudentNotificationsApiService } from '@pw/api/notification';
import { environment } from '@pw/shared/environment';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { PatchNotificationRequest, StudentNotification, StudentNotificationNameEnum, TaskItemName, TaskItemStatusEnum } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StudentNotificationService {
  studentNotifications$: Observable<StudentNotification[]>;
  studentNotificationsError$: Observable<string>;

  private studentNotifications = new BehaviorSubject<StudentNotification[]>([]);
  private studentNotificationsError = new BehaviorSubject<string>(undefined);

  private readonly notificationService = inject(StudentNotificationsApiService);

  constructor() {
    this.studentNotifications$ = this.studentNotifications.asObservable();
    this.studentNotificationsError$ = this.studentNotificationsError.asObservable();
  }

  @Memoize((masterProfileId: string) => masterProfileId)
  getStudentNotifications(bearerToken: string, masterProfileId: string): Observable<StudentNotification[]> {
    return this.notificationService.listNotifications(bearerToken, masterProfileId).pipe(
      map((response) => {
        let responseFiltered = response;

        // TODO: Remove this when notification is needed
        if (!environment.FEATURE_FLAGS.PAYMENTS_NOTIFICATION_ENABLED) {
          responseFiltered = response?.filter((notification) => notification.name !== StudentNotificationNameEnum.payment_reminders);
        }

        return responseFiltered;
      }),
      tap((response) => this.studentNotifications.next(response)),
      catchError((error: Error) => {
        console.error('Error occurred while getting the notifications', error);
        this.studentNotificationsError.next(error.message);
        return of([]);
      }),
    );
  }

  updateNotification(bearerToken: string, masterProfileId: string, notificationId: string, payload: PatchNotificationRequest) {
    return this.notificationService.updateNotification(bearerToken, masterProfileId, notificationId, payload);
  }

  getPreArrivalTasks(): StudentNotification | undefined {
    return this.studentNotifications.value.find((notification) => notification.name === StudentNotificationNameEnum.pre_arrival_tasks);
  }

  getPendingTasks(): number {
    return this.getPreArrivalTasks()?.tasks?.filter((task) => task.status !== TaskItemStatusEnum.completed).length || 0;
  }

  getPaymentReminders(): StudentNotification | undefined {
    return this.studentNotifications.value.find((notification) => notification.name === StudentNotificationNameEnum.payment_reminders);
  }

  getPendingPayments(): number {
    return (this.getPaymentReminders()?.payments || []).reduce((acc, payment) => acc + payment.itemsOverDue + payment.itemsDueSoon, 0);
  }

  findPreArrivalTask(taskName: TaskItemName) {
    return this.getPreArrivalTasks()?.tasks?.find((task) => task.name === taskName);
  }
}
