export enum GtmEventNames {
  // Clicks on 'contact us' link
  CHANGE_PHONE_LOGIN_CONTACT_US = 'change-phone-log-in-contact-us',
  MFA_CHOICE_CONTACT_US = 'mfa-choice-contact-us',
  MFA_EMAIL_REQUEST_VERIFICATION_CONTACT_US = 'mfa-email-request-verification-contact-us',
  MFA_EMAIL_ADD_VERIFICATION_CONTACT_US = 'mfa-email-add-verification-contact-us',
  PLAIN_PHONE_REQUEST_VERIFICATION_CONTACT_US = 'plain-phone-request-verification-contact-us',
  HASHED_PHONE_REQUEST_VERIFICATION_CONTACT_US = 'hashed-phone-request-verification-contact-us',
  CHANGE_PHONE_REQUEST_VERIFICATION_CONTACT_US = 'change-phone-request-verification-contact-us',
  PLAIN_PHONE_ADD_VERIFICATION_CONTACT_US = 'plain-phone-add-verification-contact-us',
  HASHED_PHONE_ADD_VERIFICATION_CONTACT_US = 'hashed-phone-add-verification-contact-us',
  CHANGE_PHONE_ADD_VERIFICATION_CONTACT_US = 'change-phone-add-verification-contact-us',
  FINISH_RESETTING_ACCOUNT_CONTACT_US = 'finish-resetting-account-contact-us',

  // Clicks on 'Sign in' link
  MFA_EMAIL_SIGN_IN_LINK = 'mfa-email-sign-in-link',
  PLAIN_PHONE_SIGN_IN_LINK = 'plain-phone-sign-in-link',
  HASHED_PHONE_SIGN_IN_LINK = 'hashed-phone-sign-in-link',

  // Clicks on radio buttons from choose 2FA method
  MFA_CHOICE_EMAIL = 'mfa-choice-email',
  MFA_CHOICE_PHONE = 'mfa-choice-phone',

  // Clicks on ‘Continue’ button
  MFA_CHOICE_CONTINUE = 'mfa-choice-continue',
  ADD_PHONE_NUMBER_CONFIRM = 'add-phone-confirm',

  // Clicks on ‘Send verification code’ button
  MFA_EMAIL_SEND_VERIFICATION_CODE = 'mfa-email-send-verification-code',
  PLAIN_PHONE_REQUEST_VERIFICATION_SEND_CODE = 'plain-phone-request-verification-send-code',
  HASHED_PHONE_REQUEST_VERIFICATION_SEND_CODE = 'hashed-phone-request-verification-send-code',
  CHANGE_PHONE_REQUEST_VERIFICATION_SEND_CODE = 'change-phone-request-verification-send-code',

  // Clicks on 'Send new code' button
  MFA_EMAIL_SEND_NEW_CODE = 'mfa-email-send-new-code',
  PLAIN_PHONE_SEND_NEW_CODE = 'plain-phone-send-new-code',
  HASHED_PHONE_SEND_NEW_CODE = 'hashed-phone-send-new-code',
  CHANGE_PHONE_SEND_NEW_CODE = 'change-phone-send-new-code',

  // Clicks on ‘Verify code’ button
  PLAIN_PHONE_VERIFY_CODE = 'plain-phone-verify-code',
  HASHED_PHONE_VERIFY_CODE = 'hashed-phone-verify-code',
  CHANGE_PHONE_VERIFY_CODE = 'change-phone-verify-code',
  MFA_EMAIL_VERIFY_CODE = 'mfa-email-verify-code',

  // Clicks on ‘Change phone number’ link
  PLAIN_PHONE_CHANGE_PHONE_NUMBER_LINK = 'plain-phone-change-phone-number-link',
  HASHED_PHONE_CHANGE_PHONE_NUMBER_LINK = 'hashed-phone-change-phone-number-link',

  // Clicks on ‘Reset account’ button
  MFA_PHONE_RESET_ACCOUNT = 'mfa-phone-reset-account',

  // Clicks on ‘Finish resetting my account’ button
  FINISH_RESETTING_ACCOUNT = 'finish-resetting-account',

  // Arrives at 'Your phone number has been successfully changed'
  FINISH_CHANGE_PHONE = 'finish-change-phone',

  // Clicks on ‘Cancel’ button
  MFA_CHOICE_CANCEL = 'mfa-choice-cancel',
  CANCEL_RESETTING_ACCOUNT = 'cancel-resetting-account',
  MFA_EMAIL_REQUEST_VERIFICATION_CANCEL = 'mfa-email-request-verification-cancel',
  PLAIN_PHONE_REQUEST_VERIFICATION_CANCEL = 'plain-phone-request-verification-cancel',
  HASHED_PHONE_REQUEST_VERIFICATION_CANCEL = 'hashed-phone-request-verification-cancel',
  CHANGE_PHONE_REQUEST_VERIFICATION_CANCEL = 'change-phone-request-verification-cancel',
  MFA_EMAIL_ADD_VERIFICATION_CANCEL = 'mfa-email-add-verification-cancel',
  HASHED_PHONE_ADD_VERIFICATION_CANCEL = 'hashed-phone-add-verification-cancel',
  PLAIN_PHONE_ADD_VERIFICATION_CANCEL = 'plain-phone-add-verification-cancel',
  CHANGE_PHONE_ADD_VERIFICATION_CANCEL = 'change-phone-add-verification-cancel',

  // Login Page
  LOGIN_FORGOT_PASSWORD = 'log-in-forgot-password',
  LOGIN_SIGN_UP = 'log-in-sign-up',
  LOGIN_SIGN_IN = 'log-in-sign-in',

  // Forgot password errors
  FORGOT_PASSWORD_INCORRECT_EMAIL = 'forgot-password-incorrect-email',
  FORGOT_PASSWORD_INCORRECT_CODE = 'forgot-password-incorrect-code',
  FORGOT_PASSWORD_EXPIRED_CODE = 'forgot-password-expired-code',
  FORGOT_PASSWORD_INCORRECT_CODE_THREE_TIMES = 'forgot-password-incorrect-code-three-times',

  // Change password errors
  CHANGE_PASSWORD_DONT_MATCH_CRITERIA = 'change-password-dont-match-criteria',
  CHANGE_PASSWORD_PASSWORDS_DONT_MATCH = 'change-password-passwords-dont-match',
  CHANGE_PASSWORD_NEW_PASSWORD_EMPTY = 'change-password-new-password-empty',
  CHANGE_PASSWORD_CONFIRM_PASSWORD_EMPTY = 'change-password-confirm-password-empty',

  // Verify SMS code errors
  VERIFY_SMS_CODE_INCORRECT_CODE = 'verify-sms-code-incorrect-code',
  VERIFY_SMS_CODE_TOO_MANY_REQUESTS = 'verify-sms-code-too-many-requests',

  // General Azure login errors
  AZURE_B2C_LOGIN_ERROR = 'azure-b2c-login-error',

  // Attendance - Home page
  HOME_ATTENDANCE_MENU_OPTION = 'home-attendance-menu-option',
  HOME_ATTENDANCE_LINK = 'home-attendance-link',
  HOME_ATTENDANCE_SUBMIT_ABSENCE_FORM_BUTTON = 'home-attendance-submit-absence-form-button',
  HOME_ATTENDANCE_CAROUSEL_LEFT_CHEVRON = 'home-attendance-carousel-left-chevron',
  HOME_ATTENDANCE_CAROUSEL_RIGHT_CHEVRON = 'home-attendance-carousel-right-chevron',

  // Attendance - Detail page
  DETAIL_ATTENDANCE_HOME_LINK = 'detail-attendance-home-link',
  DETAIL_ATTENDANCE_SUBMIT_ABSENCE_FORM_BUTTON = 'detail-attendance-submit-absence-form-button',
  DETAIL_ATTENDANCE_MONTHLY_TAB = 'detail-attendance-monthly-tab',
  DETAIL_ATTENDANCE_MONTHLY_FILTER = 'detail-attendance-monthly-filter',
  DETAIL_ATTENDANCE_WEEKLY_TAB = 'detail-attendance-weekly-tab',
  DETAIL_ATTENDANCE_WEEKLY_FILTER = 'detail-attendance-weekly-filter',
  DETAIL_ATTENDANCE_OVERALL_TAB = 'detail-attendance-overall-tab',
  DETAIL_ATTENDANCE_CAROUSEL_LEFT_CHEVRON = 'detail-attendance-carousel-left-chevron',
  DETAIL_ATTENDANCE_CAROUSEL_RIGHT_CHEVRON = 'detail-attendance-carousel-right-chevron',
  DETAIL_ATTENDANCE_COLLEGE_EMAIL_LINK = 'detail-attendance-college-email-link',
  DETAIL_ATTENDANCE_POLICY_BUTTON = 'detail-attendance-policy-button',
}
