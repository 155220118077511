<div class="pw-menu__items">
  <div
    class="pw-menu__icon"
    [attr.data-gtm-id]="(isPanelOpen$ | async) ? 'notification-bell-close' : 'notification-bell-open'"
    (click)="toggleNotificationPanel()"
  >
    <img alt="Clip folder icon" [ngSrc]="taskIconImg" width="32" height="32" />
    <span *ngIf="pendingNotifications > 0" class="pw-menu__icon-badge">{{ pendingNotifications }}</span>
    <img
      *ngIf="(studentNotificationsError$ | async) !== undefined"
      alt="Warning icon"
      class="pw-menu__icon-error-badge"
      [ngSrc]="taskIconErrorBadgeImg"
      width="18"
      height="17"
    />
  </div>
  <button class="pw-menu__trigger-button" (click)="toggleMenu()" (pwClickOutside)="onOutsideClick()">
    <ng-content></ng-content>
    <i class="pi" [ngClass]="chevron"></i>
  </button>
</div>

<nav class="pw-menu__menu" [ngClass]="{ 'pw-menu__menu--open': isMenuOpen }" (pwClickOutside)="onOutsideClick()">
  <ul>
    <li *ngFor="let menuItem of menuItems">
      <hr *ngIf="menuItem.separator" class="pw-menu__item--separator" />
      <a [id]="menuItem.id ?? 'menu-link'" role="menuitem" *ngIf="!menuItem.separator" (click)="executeMenuItemCommand(menuItem)" class="pw-menu__item">
        {{ menuItem.label }}
        <ng-container *ngIf="menuItem.icon" [ngSwitch]="menuItem.icon">
          <ng-container *ngSwitchCase="'external-link'">
            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5563 7.07069H8.48522V8.06771L13.8486 8.07125L6.71745 15.2024L7.42456 15.9095L14.5557 8.77835L14.5593 14.1418H15.5563V7.07069Z"
                fill="black"
              />
            </svg>
          </ng-container>
          <div *ngSwitchDefault>
            <i class="pi" [ngClass]="'pi-' + menuItem.icon"></i>
          </div>
        </ng-container>
      </a>
    </li>
  </ul>
</nav>
