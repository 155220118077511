<pw-popup-shell popupTitle="Sign out of student portal?" (homeLinkClicked)="hideSignOut()">
  <div class="sign-out__content">
    <span>Signing out will close down all applications currently being used.</span>
    <span>Please make sure you have saved everything before you sign out.</span>
  </div>
  <div class="sign-out__footer">
    <pw-button label="Cancel" [size]="buttonSize.Medium" [secondary]="true" (buttonClicked)="hideSignOut()" i18n-label></pw-button>
    <pw-button label="Sign out" [size]="buttonSize.Medium" (buttonClicked)="signOut()" i18n-label></pw-button>
  </div>
</pw-popup-shell>
