import { definePreset } from '@primeng/themes';
import Lara from '@primeng/themes/lara';

export const pwPreset = definePreset(Lara, {
  semantic: {
    colorScheme: {
      light: {
        primary: {
          color: '#005de8',
          hoverColor: '#005de8',
        },
      },
    },
  },
});
