<ng-template #providedContent>
  <ng-content></ng-content>
</ng-template>

<p-message [severity]="severity">
  <ng-template #icon>
    <span class="pw-message-icon" [ngStyle]="iconStyle ? iconStyle : null" [ngClass]="severity ? 'pw-icon-' + severity : ''" *ngIf="displayIcon"></span>
  </ng-template>
  <span *ngIf="summary" class="p-message-summary">{{ summary }}</span>
  <div class="p-message-detail">
    {{ detail }}
    <ng-container *ngTemplateOutlet="providedContent"></ng-container>
  </div>
  <div>
    <p-button icon="pi pi-times" [link]="true" (onClick)="closeMessage()" />
  </div>
</p-message>
