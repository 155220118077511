/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { Component, ContentChild, TemplateRef } from '@angular/core';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'pw-card-shell',
  standalone: true,
  imports: [CommonModule, CardModule],
  templateUrl: './card-shell.component.html',
  styleUrls: ['./card-shell.component.scss'],
})
export class CardShellComponent {
  @ContentChild('header', { static: false }) headerTemplate: TemplateRef<any>;
}
