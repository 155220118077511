import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { environment } from '@pw/shared/environment';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'pw-terms-of-use',
  standalone: false,
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnDestroy, AfterViewInit {
  @Input() showDisclaimer = true;
  @Input() showMainTitle = true;

  @ViewChild('termsOfUseText', { read: ElementRef, static: true }) termsOfUseText: ElementRef<HTMLDivElement> | undefined;

  public readonly termsOfUseUrl = `${environment.PORTAL_PATH}/terms-of-use`;
  public readonly supportEmail = environment.SUPPORT_EMAIL;
  public readonly privacyUrl = environment.PRIVACY_URL;
  public readonly UPDATE_DATE = '02/Aug/2022';

  private subscriptions = new Subscription();

  constructor(private readonly renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const termsOfUseTextElement = this.termsOfUseText?.nativeElement;
    if (termsOfUseTextElement) {
      this.subscriptions.add(
        fromEvent(termsOfUseTextElement, 'scroll')
          .pipe(debounceTime(20), distinctUntilChanged())
          .subscribe(() => {
            const noGradientClass = 'terms-of-use__text--no-gradient';
            const almostAtBottom = termsOfUseTextElement.scrollTop + termsOfUseTextElement.clientHeight >= termsOfUseTextElement.scrollHeight - 48;
            if (almostAtBottom) {
              this.renderer.addClass(termsOfUseTextElement, noGradientClass);
            } else {
              this.renderer.removeClass(termsOfUseTextElement, noGradientClass);
            }
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
