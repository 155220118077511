import { DOCUMENT } from '@angular/common';
import { Directive, Inject, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
// eslint-disable-next-line no-var
declare var __webpack_public_path__: string;

const hiddenClassName = 'pw-hidden';
@Directive({
  selector: '[pwAddShowHideToPasswordInputs]',
  standalone: false,
})
export class AddShowHideToPasswordInputsDirective implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const passwordFields: NodeListOf<HTMLInputElement> = this.viewContainerRef.element.nativeElement.querySelectorAll(`input[type="password"]`);
    passwordFields.forEach((passwordField: HTMLInputElement): void => {
      const showIconElement: HTMLImageElement = this.document.createElement('img');
      this.styleIconAsShowPassword(showIconElement);
      const hideIconElement: HTMLImageElement = this.document.createElement('img');
      this.styleIconAsHidePassword(hideIconElement);
      this.renderer.addClass(hideIconElement, hiddenClassName); // Initially, 'Hide Password' will be hidden

      // Capture click on 'Show Password'
      this.subscriptions.add(
        fromEvent(showIconElement, 'click').subscribe(() => {
          passwordField.type = 'text';
          this.renderer.addClass(showIconElement, hiddenClassName);
          this.renderer.removeClass(hideIconElement, hiddenClassName);
        }),
      );

      // Capture click on 'Hide Password'
      this.subscriptions.add(
        fromEvent(hideIconElement, 'click').subscribe(() => {
          passwordField.type = 'password';
          this.renderer.removeClass(showIconElement, hiddenClassName);
          this.renderer.addClass(hideIconElement, hiddenClassName);
        }),
      );

      const wrapperDiv: HTMLElement = this.document.createElement('div');
      this.renderer.addClass(wrapperDiv, 'pw-input-wrapper');
      if (passwordField) {
        passwordField?.insertAdjacentElement('beforebegin', wrapperDiv);
        this.renderer.addClass(passwordField, 'pw-input--with-right-icon');
      }
      wrapperDiv.appendChild(passwordField);
      wrapperDiv.appendChild(showIconElement);
      wrapperDiv.appendChild(hideIconElement);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private styleIconAsShowPassword(iconElement: HTMLImageElement) {
    this.renderer.addClass(iconElement, 'pw-icon');
    this.renderer.addClass(iconElement, 'pw-icon--right');
    this.renderer.setAttribute(iconElement, 'src', `${__webpack_public_path__}assets/icons/eye.svg`);
    this.renderer.setAttribute(iconElement, 'title', $localize`Show password`);
    this.renderer.setAttribute(iconElement, 'alt', iconElement.title);
  }

  private styleIconAsHidePassword(iconElement: HTMLImageElement) {
    this.renderer.addClass(iconElement, 'pw-icon');
    this.renderer.addClass(iconElement, 'pw-icon--right');
    this.renderer.setAttribute(iconElement, 'src', `${__webpack_public_path__}assets/icons/eye-slash.svg`);
    this.renderer.setAttribute(iconElement, 'title', $localize`Hide password`);
    this.renderer.setAttribute(iconElement, 'alt', iconElement.title);
  }
}
