import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StudentNotification } from '@pw/notification-data-access';
import { colleges } from '@pw/profile-data-access';

@Component({
  selector: 'pw-pre-arrival-info-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pre-arrival-info-notification.component.html',
  styleUrls: ['./pre-arrival-info-notification.component.scss'],
})
export class PreArrivalInfoNotificationComponent implements OnChanges {
  @Input() preArrivalInfoNotificationData: StudentNotification | undefined;

  infoUrl = '';

  ngOnChanges({ preArrivalInfoNotificationData }: SimpleChanges): void {
    if (preArrivalInfoNotificationData?.currentValue) {
      this.infoUrl = colleges.find((college) => college.code === this.preArrivalInfoNotificationData.collegeCode)?.infoUrl;
    }
  }
}
