import { AfterViewInit, Component, inject } from '@angular/core';
import { PathwaysAuthService } from '@pw/account/auth';
import { PathwaysAccountRoutes } from '@pw/shared/environment';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { GtmEventNames } from '@pw/shared/types';

@Component({
  selector: 'pw-phone-number-changed',
  standalone: false,
  templateUrl: './phone-number-changed.component.html',
  styleUrls: ['./phone-number-changed.component.scss'],
})
export class PhoneNumberChangedComponent implements AfterViewInit {
  private readonly authService = inject(PathwaysAuthService);
  private readonly gtmService = inject(PathwaysGoogleTagManagerService);

  ngAfterViewInit(): void {
    this.gtmService.pushTag({ event: GtmEventNames.FINISH_CHANGE_PHONE });
  }

  goToSignIn(): void {
    this.authService.logout(PathwaysAccountRoutes.SIGN_IN);
  }
}
