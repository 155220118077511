import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
// eslint-disable-next-line no-var
declare var __webpack_public_path__: string;

enum VALIDATION_ID {
  LOWERCASE,
  UPPERCASE,
  NUMBER,
  SYMBOL,
  LENGTH,
}

interface ValidationRule {
  label: string;
  validator: (password: string) => boolean;
}

const MIN_PASSWORD_LENGTH = 10;

@Component({
  selector: 'pw-password-validator',
  standalone: false,
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss'],
})
export class PasswordValidatorComponent implements OnChanges {
  @Input() passwordToCheck = '';
  @Output() isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  public circleCheckedSrc = `${__webpack_public_path__}assets/icons/circle-checked.svg`;
  public circleOffSrc = `${__webpack_public_path__}assets/icons/circle-off.svg`;

  // Password validations
  public validationRules: Record<VALIDATION_ID, ValidationRule> = {
    [VALIDATION_ID.LOWERCASE]: {
      label: $localize`1 lowercase character`,
      validator: (password: string) => /[a-z]+/g.test(password),
    },
    [VALIDATION_ID.UPPERCASE]: {
      label: $localize`1 uppercase character`,
      validator: (password: string) => /[A-Z]+/g.test(password),
    },
    [VALIDATION_ID.NUMBER]: {
      label: $localize`1 number`,
      validator: (password: string) => /\d+/g.test(password),
    },
    [VALIDATION_ID.SYMBOL]: {
      label: $localize`1 symbol`,
      validator: (password: string) => {
        const expectedCharacters = '!"#$%&\'()*+,-.\\/:;<=>?@[\\]^_`{|}~';
        const validCharsRegex = new RegExp('[' + expectedCharacters + ']', 'g');
        const invalidCharsRegex = new RegExp('[^\\w' + expectedCharacters + ']', 'g');

        // must have at least one valid symbol and not have any invalid symbols
        return validCharsRegex.test(password) && !invalidCharsRegex.test(password);
      },
    },
    [VALIDATION_ID.LENGTH]: {
      label: $localize`${MIN_PASSWORD_LENGTH}:min_password_length: characters minimum`,
      validator: (password: string) => password.length >= MIN_PASSWORD_LENGTH,
    },
  };

  public validationStatuses: Record<VALIDATION_ID, boolean> = {
    [VALIDATION_ID.LOWERCASE]: false,
    [VALIDATION_ID.UPPERCASE]: false,
    [VALIDATION_ID.NUMBER]: false,
    [VALIDATION_ID.SYMBOL]: false,
    [VALIDATION_ID.LENGTH]: false,
  };

  private validatePassword(): void {
    for (const validationKey in this.validationRules) {
      this.validationStatuses[validationKey] = this.validationRules[validationKey].validator(this.passwordToCheck);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('passwordToCheck' in changes) {
      this.validatePassword();
      this.isValid.emit(!Object.values(this.validationStatuses).includes(false));
    }
  }
}
