import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { StudentNotification, StudentNotificationNameEnum, StudentNotificationService } from '@pw/notification-data-access';
import { environment } from '@pw/shared/environment';
import { Severity } from '@pw/shared/types';
import { Observable } from 'rxjs';
import { NotificationPanelService } from '../../../services';
import { NotificationToastComponent } from '../../molecules';
import { PaymentsDueNotificationComponent } from '../payments-due-notification/payments-due-notification.component';
import { NotificationInfoSectionComponent } from './notification-info-section/notification-info-section.component';
import { PreArrivalTasksNotificationComponent } from './pre-arrival-tasks/pre-arrival-tasks-notification.component';

@Component({
  selector: 'pw-notification-panel',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    PreArrivalTasksNotificationComponent,
    NotificationToastComponent,
    NotificationInfoSectionComponent,
    PaymentsDueNotificationComponent,
  ],
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
})
export class NotificationPanelComponent {
  @Input() studentNotifications: StudentNotification[] = [];
  @Input() infoNotifications: StudentNotification[] = [];

  studentNotificationsError$: Observable<string>;
  paymentReminders: StudentNotification;
  pendingPayments = 0;

  closeIcon = 'assets/icons/close.svg';
  celebrationImg = 'assets/illustrations/celebration.svg';
  serverErrorImg = 'assets/illustrations/server-error.png';
  notificationNameEnum = StudentNotificationNameEnum;
  toastSeverity = Severity;

  readonly supportEmail = environment.SUPPORT_EMAIL;

  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly notificationService = inject(StudentNotificationService);

  constructor() {
    this.studentNotificationsError$ = this.notificationService.studentNotificationsError$;
    this.paymentReminders = this.notificationService.getPaymentReminders();
    this.pendingPayments = this.notificationService.getPendingPayments();
  }

  closePanel() {
    this.notificationPanelService.togglePanel(false);
  }

  refresh() {
    window.location.reload();
  }
}
