import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@klg/shared/storage';
import { PW_NOTIFICATION_PANEL_OPEN } from '@pw/shared/types';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationPanelService {
  isPanelOpen$: Observable<boolean>;
  isConfirmUkAddressOpen$: Observable<boolean>;

  private readonly isPanelOpen = new BehaviorSubject<boolean>(false);
  private readonly isConfirmUkAddressOpen = new BehaviorSubject<boolean>(false);

  private readonly localStorageService = inject(LocalStorageService);

  constructor() {
    this.isPanelOpen$ = this.isPanelOpen.asObservable();
    this.isConfirmUkAddressOpen$ = this.isConfirmUkAddressOpen.asObservable();
  }

  togglePanel(isOpen: boolean, keepPanelOpen = false) {
    this.isPanelOpen.next(isOpen);
    this.localStorageService.setItem(PW_NOTIFICATION_PANEL_OPEN, isOpen || keepPanelOpen);
  }

  toggleConfirmUkAddress(isOpen: boolean) {
    this.isConfirmUkAddressOpen.next(isOpen);
  }
}
